.team_bio_image_container {
    align-content: center;
    margin: 5px;
    display: block;

}

.flag-container {
    height: 30px;
    width: 30px;
    margin-left: auto;
    margin-right: 20px;
}

.flag-container .flag-element {
    cursor: pointer;
}
